import { gql } from "@apollo/client";
import type { IMemberInfo } from "@adc/utilities/imemberinfo";
import type { TypedDocumentNode } from "@apollo/client/core";

type Data = {
  memberInformation: IMemberInfo;
};
type Variables = {
  advantageNumber: string;
};

export const MemberInformationQuery: TypedDocumentNode<Data, Variables> = gql`
  query memberInformationQuery($advantageNumber: String!) {
    memberInformation(
      advantageNumber: $advantageNumber
    ) {
      name
      loyaltyStatus
      loyaltyBalance
      memberPreferences {
        bookingPreference {
          homeAirport
          seatType
        }
      }
      programs {
        advantageBusiness(associatedMember: true) {
          companies {
            name
            accountNumber
            loggedInUserRole
            travelers {
              firstName
              lastName
              role
              uuid
              maskedAccountNumber
              isLoggedInUser
            }
          }
        }

        tripLink {
          advantageNumber
          discountInfos {
            companyName
            discountCode
          }
        }

        airpass {
          airpassProgramDetails {
            advantageNumber
            isCompanionAllowed
          }
        }
      }
    }
  }
`;
