import axios from "axios";
import type { AirportCode } from "./iairportlookup";
import type { ReactiveController, ReactiveControllerHost } from "lit";

export class AirportLookupController implements ReactiveController {
  private readonly host: ReactiveControllerHost;
  public filterAirports: any[] = [];

  constructor(host: ReactiveControllerHost) {
    (this.host = host).addController(this);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  hostConnected() {
  }


  async searchAirports(event, control) {
      const searchText: string = event.target.adcGeolocator.value;
      this[control] = searchText;
      if (searchText && event.keyCode !== 16 && searchText.length >= 3)  { 
        try { 
          const response = await axios.get<any>(`/home/ajax/airportLookup?searchText=${searchText}`)
          this.filterResponse(Object.assign([], response.data)); 
          this.host.requestUpdate();
        } catch (err) {
            console.log(err);
        } 
      }
}
  
   private filterResponse(airportList: AirportCode[]): void {
      this.filterAirports = airportList.map((element) => this.transformDisplayName(element));
    }
  
   private transformDisplayName(item: AirportCode) {
      const location = item.stateCode ? item.stateCode : item.countryName;
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      item.displayName = `${item.code} - ${item.name}, ${location}`;
      item.selectionName = item.code;
      return item;
    }

}
